[data-v-885bfab2] .custom-tree-node {
  width: 100%;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
[data-v-885bfab2] .custom-tree-node .node-operation {
  float: right;
}
[data-v-885bfab2] .custom-tree-node i[class*=el-icon] + i[class*=el-icon] {
  margin-left: 6px;
}
[data-v-885bfab2] .custom-tree-node .el-icon-plus {
  color: #409eff;
}
[data-v-885bfab2] .custom-tree-node .el-icon-delete {
  color: #157a0c;
}