
.el-card[data-v-a30288cc] .el-card__body {
  background-image: url(../../static/img/weather.b9766cff.png);
  background-repeat: no-repeat;
  background-size: cover;
}
.el-card[data-v-a30288cc] .weatherBox {
  color: #FFFFFF;
}
.weatherTime[data-v-a30288cc] {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  line-height: 30px;
}
.temperature[data-v-a30288cc] {
  width: 100%;
  text-align: center;
  font-size: 60px;
  margin: 20px 0 0;
}
.text[data-v-a30288cc] {
  width: 100%;
  text-align: center;
  font-size: 35px;
}
