.dataBoard[data-v-65bfb02c] {
  height: 100%;
  overflow: hidden;
}
.dataBoard .dataBoard-item[data-v-65bfb02c] {
  height: 100%;
}
.dataBoard[data-v-65bfb02c] .el-card {
  width: 100%;
  height: 100%;
}
.dataBoard[data-v-65bfb02c] .el-card .el-card__body {
  padding: 0;
  height: 100%;
}
.dataBoard .dataBoard-body[data-v-65bfb02c] {
  padding-left: 30px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.dataBoard .dataBoard-body .dataBoard-body-item[data-v-65bfb02c] {
  width: 66px;
  height: 66px;
  margin-right: 16px;
  border-radius: 50%;
  text-align: center;
  line-height: 66px;
  font-size: 36px !important;
  -ms-flex-negative: 0;
      flex-shrink: 0;
}
.dataBoard .dataBoard-body .dataBoard-body-item.dataBoard-body-item1[data-v-65bfb02c] {
  background: #f2ebfb;
  color: #7b1ae1;
}
.dataBoard .dataBoard-body .dataBoard-body-item.dataBoard-body-item2[data-v-65bfb02c] {
  background: #edf8fe;
  color: #4ab8ff;
}
.dataBoard .dataBoard-body .dataBoard-body-item.dataBoard-body-item3[data-v-65bfb02c] {
  background: #fff7e4;
  color: #ff8b58;
}
.dataBoard .dataBoard-body .dataBoard-body-item.dataBoard-body-item4[data-v-65bfb02c] {
  background: #fff2f5;
  color: #fc5b87;
}
.dataBoard .dataBoard-body .dataBoard-body-item.dataBoard-body-item5[data-v-65bfb02c] {
  background: rgba(51, 185, 50, 0.1);
  color: #33b932;
}
.dataBoard .dataBoard-body .dataBoard-body-item.dataBoard-body-item6[data-v-65bfb02c] {
  background: rgba(255, 59, 59, 0.1);
  color: #ff3b3b;
}
.dataBoard .dataBoard-body .text[data-v-65bfb02c] {
  display: inline-block;
  height: 56px;
}
.dataBoard .dataBoard-body .text .num[data-v-65bfb02c] {
  font-size: 20px;
  line-height: 36px;
  height: 36px;
  margin: 0;
}
.dataBoard .dataBoard-body .text .name[data-v-65bfb02c] {
  font-size: 14px;
  color: #666;
  margin: 0;
}