
.btn[data-v-841d6416] {
  margin: 20px 0px;
}
.main[data-v-841d6416] {
  border: 2px solid #dcdfe6;
  height: 600px;
  overflow: auto;
}
.tools[data-v-841d6416] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.total[data-v-841d6416] {
  width: 80px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}
.main[data-v-841d6416]::-webkit-scrollbar {
  width: 6px;
}
/* 修改 滚动条的 下面 的 样式 */
.main[data-v-841d6416]::-webkit-scrollbar-track {
  background-color: white;
  border-radius: 2em;
}
/* 修改 滑块 */
.main[data-v-841d6416]::-webkit-scrollbar-thumb {
  background-color: #dcdfe6;
  border-radius: 2em;
}
[data-v-841d6416] .el-dialog {
  height: 735px;
  font-family: "\6977\4F53";
}
[data-v-841d6416] .el-dialog__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  background: #303133;
}
[data-v-841d6416] .el-dialog__title,[data-v-841d6416] .el-dialog__headerbtn .el-dialog__close {
  color: white;
}
[data-v-841d6416] .el-dialog__body {
  padding: 20px;
}
[data-v-841d6416] .el-select {
  width: 95px;
  height: 28px;
  margin: 5px 20px 0px 20px;
}
