.flow-container[data-v-3e6bfe08] {
  display: inline-block;
  background: #fff;
  width: 100%;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  text-align: center;
  overflow: auto;
}
.flow-container.flow-container-preview[data-v-3e6bfe08] {
  height: calc(100% - 10px);
}
.flow-container.flow-container-preview[data-v-3e6bfe08].branch-wrap .branch-box {
  background: #fff;
}
.flow-container.flow-container-preview[data-v-3e6bfe08].branch-wrap .branch-box > .col-box:first-of-type::before, .flow-container.flow-container-preview[data-v-3e6bfe08].branch-wrap .branch-box > .col-box:first-of-type::after {
  background: #fff !important;
}
.flow-container.flow-container-preview[data-v-3e6bfe08].branch-wrap .branch-box > .col-box:last-of-type::before, .flow-container.flow-container-preview[data-v-3e6bfe08].branch-wrap .branch-box > .col-box:last-of-type::after {
  background: #fff;
}
.flow-container.flow-container-preview[data-v-3e6bfe08].node-wrap-box.approver::before {
  background: #fff;
}
.flow-container.flow-container-preview[data-v-3e6bfe08] .node-wrap .flow-path-card:hover .title-text {
  border-bottom: none;
}
.flow-container.flow-container-preview[data-v-3e6bfe08] .node-wrap .flow-path-card.condition:hover, .flow-container.flow-container-preview[data-v-3e6bfe08] .node-wrap .flow-path-card.timer:hover {
  -webkit-box-shadow: 0 0 0 2px #b6b6b6, 0 0 5px 4px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 0 2px #b6b6b6, 0 0 5px 4px rgba(0, 0, 0, 0.2);
}
.flow-container.flow-container-preview[data-v-3e6bfe08] .node-wrap .flow-path-card.start-node:hover, .flow-container.flow-container-preview[data-v-3e6bfe08] .node-wrap .flow-path-card.approver:hover, .flow-container.flow-container-preview[data-v-3e6bfe08] .node-wrap .flow-path-card.subFlow:hover {
  -webkit-box-shadow: 0 0 0 2px #b6b6b6, 0 0 5px 4px rgba(0, 0, 0, 0.2);
          box-shadow: 0 0 0 2px #b6b6b6, 0 0 5px 4px rgba(0, 0, 0, 0.2);
}
.flow-container.flow-container-preview[data-v-3e6bfe08] .node-wrap .flow-path-card.start-node .header, .flow-container.flow-container-preview[data-v-3e6bfe08] .node-wrap .flow-path-card.approver .header, .flow-container.flow-container-preview[data-v-3e6bfe08] .node-wrap .flow-path-card.subFlow .header {
  background-color: #b6b6b6;
}
.flow-container.flow-container-preview[data-v-3e6bfe08] .node-wrap .flow-path-card.state-past .header {
  background-color: #67c23a;
}
.flow-container.flow-container-preview[data-v-3e6bfe08] .node-wrap .flow-path-card.state-past:hover {
  -webkit-box-shadow: 0 0 0 2px #67c23a, 0 0 5px 4px rgba(103, 194, 58, 0.2);
          box-shadow: 0 0 0 2px #67c23a, 0 0 5px 4px rgba(103, 194, 58, 0.2);
}
.flow-container.flow-container-preview[data-v-3e6bfe08] .node-wrap .flow-path-card.state-curr .header {
  background-color: #1890ff;
}
.flow-container.flow-container-preview[data-v-3e6bfe08] .node-wrap .flow-path-card.state-curr:hover {
  -webkit-box-shadow: 0 0 0 2px #1890ff, 0 0 5px 4px rgba(24, 144, 255, 0.2);
          box-shadow: 0 0 0 2px #1890ff, 0 0 5px 4px rgba(24, 144, 255, 0.2);
}
.scale-slider[data-v-3e6bfe08] {
  position: fixed;
  right: 20px;
  bottom: 30px;
  z-index: 199;
}
.scale-slider .btn[data-v-3e6bfe08] {
  display: inline-block;
  padding: 4px;
  border: 1px solid #cacaca;
  border-radius: 3px;
  background: #fff;
  margin-left: 10px;
  margin-right: 10px;
  cursor: pointer;
}
.tips[data-v-3e6bfe08] {
  position: absolute;
  left: 20px;
  top: 20px;
  z-index: 199;
  text-align: left;
}
.tips .tips-item[data-v-3e6bfe08] {
  line-height: 20px;
  font-size: 16px;
  display: inline-block;
  margin-right: 15px;
}
.tips .tips-item .icon[data-v-3e6bfe08] {
  font-size: 20px;
  margin-right: 5px;
  color: #b6b6b6;
}
.tips .tips-item .icon.success[data-v-3e6bfe08] {
  color: #67c23a;
}
.tips .tips-item .icon.current[data-v-3e6bfe08] {
  color: #1890ff;
}