.sign-dialog[data-v-63f4da9e] .el-dialog__body {
  overflow: hidden;
  height: 320px;
  overflow: auto;
  overflow-x: hidden;
  padding: 23px 14px 2px !important;
}
.sign-main-box[data-v-63f4da9e] {
  border: 1px solid rgb(224, 238, 238);
  width: 100%;
  height: 300px;
  background-color: rgb(247, 247, 247);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: -10px;
  margin-bottom: -10px;
  position: relative;
}
.sign-main-box .tip[data-v-63f4da9e] {
  height: 300px;
  line-height: 300px;
  text-align: center;
  position: absolute;
  left: 0;
  top: 0;
  right: 0;
  color: #9d9d9f;
  font-size: 16px;
  pointer-events: none;
}