.commonFunc-box[data-v-62a2f795] .el-card__body {
  width: 100%;
  height: calc(100% - 55px);
}
.commonFunc-box .commonFunc-box-body[data-v-62a2f795] {
  padding: 0 30px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.commonFunc-box .commonFunc-box-body .item[data-v-62a2f795] {
  display: block;
  text-align: center;
}
.commonFunc-box .commonFunc-box-body .item i[data-v-62a2f795] {
  display: inline-block;
  height: 40px;
  font-size: 40px !important;
  margin-bottom: 10px;
}
.commonFunc-box .commonFunc-box-body .item .name[data-v-62a2f795] {
  font-size: 14px;
  line-height: 20px;
}