.singleImg-container[data-v-7a15ae4a] {
  width: 100px;
  height: 100px;
}
.singleImg-container .img-uploader[data-v-7a15ae4a] .el-upload {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.singleImg-container .img-uploader[data-v-7a15ae4a] .el-upload:hover {
  border-color: #409eff;
}
.singleImg-container .icon-box[data-v-7a15ae4a] {
  position: relative;
}
.singleImg-container .icon-box .upload-tip[data-v-7a15ae4a] {
  position: absolute;
  font-size: 14px;
  color: #8c939d;
  left: 0;
  right: 0;
  padding: 0 5px;
  bottom: 10px;
  line-height: 20px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  word-break: break-all;
}
.singleImg-container .img-uploader-icon[data-v-7a15ae4a] {
  font-size: 28px;
  color: #8c939d;
  width: 100px;
  height: 100px;
  line-height: 80px;
  text-align: center;
}
.singleImg-container .el-upload-list[data-v-7a15ae4a] {
  width: 100px;
  height: 100px;
  display: block;
}
.singleImg-container .el-upload-list .el-upload-list__item[data-v-7a15ae4a],
.singleImg-container .el-upload-list .el-image[data-v-7a15ae4a] {
  width: 100px;
  height: 100px;
}