[data-v-a1fe5bba] .custom-tree-node {
  width: 100%;
  font-size: 14px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
[data-v-a1fe5bba] .custom-tree-node .node-operation {
  float: right;
}
[data-v-a1fe5bba] .custom-tree-node i[class*=el-icon] + i[class*=el-icon] {
  margin-left: 6px;
}
[data-v-a1fe5bba] .custom-tree-node .el-icon-plus {
  color: #409eff;
}
[data-v-a1fe5bba] .custom-tree-node .el-icon-delete {
  color: #157a0c;
}
.select-item[data-v-a1fe5bba] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px dashed #fff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.select-item .el-input + .el-input[data-v-a1fe5bba] {
  margin-left: 4px;
}
.select-item + .select-item[data-v-a1fe5bba] {
  margin-top: 4px;
}
.select-item.sortable-chosen[data-v-a1fe5bba] {
  border: 1px dashed #409eff;
}
.select-item .select-line-icon[data-v-a1fe5bba] {
  line-height: 32px;
  font-size: 22px;
  padding: 0 4px;
  color: #606266;
}
.select-item .select-line-icon .icon-ym-darg[data-v-a1fe5bba] {
  font-size: 20px;
  line-height: 31px;
  display: inline-block;
}
.select-item .close-btn[data-v-a1fe5bba] {
  cursor: pointer;
  color: #f56c6c;
}
.select-item .option-drag[data-v-a1fe5bba] {
  cursor: move;
}