
.el-tree-select .select-option[data-v-8dccf898] {
  display: none !important;
}
[aria-disabled='true'] > .el-tree-node__content[data-v-8dccf898] {
  color: inherit !important;
  background: transparent !important;
  cursor: no-drop !important;
}
.el-tree-select-popper[data-v-8dccf898] {
  max-height: 400px;
  overflow: auto;
}
.el-tree-select-popper.disabled[data-v-8dccf898] {
  display: none !important;
}
.el-tree-select-popper .el-button--small[data-v-8dccf898] {
  width: 25px !important;
  min-width: 25px !important;
}
.el-tree-select-popper[x-placement^='bottom'][data-v-8dccf898] {
  margin-top: 5px;
}
.mb10[data-v-8dccf898] {
  margin-bottom: 10px;
}
.no-data[data-v-8dccf898] {
  height: 32px;
  line-height: 32px;
  font-size: 14px;
  color: #cccccc;
  text-align: center;
}
