.right-board[data-v-2a4fff9e] {
  width: 340px;
  position: absolute;
  right: 0;
  top: 0;
  padding-top: 3px;
  height: 100%;
  background: #fff;
  border-radius: 4px;
}
.right-board .field-box[data-v-2a4fff9e] {
  position: relative;
  height: calc(100% - 42px);
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  overflow: hidden;
}
.right-board .el-scrollbar[data-v-2a4fff9e] {
  height: 100%;
}
[data-v-2a4fff9e] .select-item {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px dashed #fff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
[data-v-2a4fff9e] .select-item .el-input + .el-input {
  margin-left: 4px;
}
[data-v-2a4fff9e] .select-item + .select-item {
  margin-top: 4px;
}
[data-v-2a4fff9e] .select-item.sortable-chosen {
  border: 1px dashed #409eff;
}
[data-v-2a4fff9e] .select-item .select-line-icon {
  line-height: 32px;
  font-size: 22px;
  padding: 0 4px;
  color: #606266;
}
[data-v-2a4fff9e] .select-item .select-line-icon .icon-ym-darg {
  font-size: 20px;
  line-height: 31px;
  display: inline-block;
}
[data-v-2a4fff9e] .select-item .close-btn {
  cursor: pointer;
  color: #f56c6c;
}
[data-v-2a4fff9e] .select-item .option-drag {
  cursor: move;
}
.time-range .el-date-editor[data-v-2a4fff9e] {
  width: 227px;
}
.time-range[data-v-2a4fff9e] .el-icon-time {
  display: none;
}
.document-link[data-v-2a4fff9e] {
  position: absolute;
  display: block;
  width: 26px;
  height: 26px;
  top: 0;
  left: 0;
  cursor: pointer;
  background: #409eff;
  z-index: 1;
  border-radius: 0 0 6px 0;
  text-align: center;
  line-height: 26px;
  color: #fff;
  font-size: 18px;
}
.node-label[data-v-2a4fff9e] {
  font-size: 14px;
}
.node-icon[data-v-2a4fff9e] {
  color: #bebfc3;
}
.right-scrollbar .el-form[data-v-2a4fff9e].el-date-editor,
.right-scrollbar .el-form[data-v-2a4fff9e].el-cascader,
.right-scrollbar .el-form[data-v-2a4fff9e].el-select {
  width: 100%;
}
.per-cell[data-v-2a4fff9e] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 18px;
}
.per-cell .el-checkbox[data-v-2a4fff9e] {
  width: 146px;
}
.per-cell.last[data-v-2a4fff9e] {
  margin-bottom: 0;
}
[data-v-2a4fff9e] .el-link--inner {
  padding: 0 20px;
}