.select-item[data-v-096a9085] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px dashed #fff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.select-item .el-input + .el-input[data-v-096a9085] {
  margin-left: 4px;
}
.select-item + .select-item[data-v-096a9085] {
  margin-top: 4px;
}
.select-item.sortable-chosen[data-v-096a9085] {
  border: 1px dashed #409eff;
}
.select-item .select-line-icon[data-v-096a9085] {
  line-height: 32px;
  font-size: 22px;
  padding: 0 4px;
  color: #606266;
}
.select-item .select-line-icon .icon-ym-darg[data-v-096a9085] {
  font-size: 20px;
  line-height: 31px;
  display: inline-block;
}
.select-item .close-btn[data-v-096a9085] {
  cursor: pointer;
  color: #f56c6c;
}
.select-item .option-drag[data-v-096a9085] {
  cursor: move;
}