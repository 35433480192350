.plan-popper {
  padding: 0 !important;
}
.plan-popper .plan-list {
  padding: 6px 0;
  max-height: 182px;
  overflow: auto;
}
.plan-popper .plan-list-item {
  height: 34px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  color: #606266;
  font-size: 14px;
  cursor: pointer;
  padding: 0 20px;
}
.plan-popper .plan-list-item:hover {
  background-color: #f5f7fa;
}
.plan-popper .plan-list .plan-list-name .el-link--inner {
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
.plan-popper .plan-list .el-icon-close:hover {
  color: #f56c6c;
}
.plan-popper .noData-txt {
  height: 34px;
  color: #999;
  font-size: 14px;
  line-height: 34px;
  text-align: center;
}