.tags .input-box.as-input[data-v-1b8c1fc3] {
  border: 1px solid #dcdfe6;
  padding-left: 6px;
  font-size: 12px;
  min-height: 32px;
  line-height: 30px;
  border-radius: 4px;
  background: white;
  color: #606266;
  cursor: pointer;
}
.tags .org-tag[data-v-1b8c1fc3] {
  margin-right: 6px;
  overflow: hidden;
  text-overflow: ellipsis;
  position: relative;
  padding-right: 1rem;
  vertical-align: middle;
}
.tags .org-tag[data-v-1b8c1fc3].el-tag__close {
  position: absolute;
  right: 2px;
  top: 50%;
  margin-top: -7px;
}
.org-select__button .org-tag[data-v-1b8c1fc3] {
  margin-bottom: 3px;
}