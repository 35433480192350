
.json-editor[data-v-03d4ca30] {
  height: 100%;
  position: relative;
}
.json-editor[data-v-03d4ca30] .CodeMirror {
  height: calc(100%);
}
.json-editor[data-v-03d4ca30] .CodeMirror-scroll {
  height: calc(100%);
}
.json-editor[data-v-03d4ca30] .cm-s-rubyblue span.cm-string {
  color: #f08047;
}
