.icon-dialog .icon-dialog-title[data-v-6961ed50] {
  font-size: 18px;
}
.icon-dialog .icon-dialog-title .el-input[data-v-6961ed50] {
  width: 260px;
  margin-left: 10px;
}
.icon-dialog[data-v-6961ed50] .el-dialog__body {
  height: 70vh;
  padding: 0 20px !important;
}
.icon-box-list[data-v-6961ed50] {
  padding-bottom: 8px;
}
.icon-box-list > .el-button[data-v-6961ed50] {
  margin: 8px 0 0 8px;
  width: 60px;
  height: 60px;
  padding: 0;
}
.icon-box-list > .el-button[data-v-6961ed50] span {
  width: 60px;
  height: 60px;
  display: inline-block;
  line-height: 60px;
  text-align: center;
  -webkit-transition: 300ms;
  transition: 300ms;
}
.icon-box-list > .el-button[data-v-6961ed50] span [class^=el-icon-] {
  margin-top: 17px;
}
.icon-box-list > .el-button[data-v-6961ed50] span .fa {
  margin-top: 17px;
}
.icon-box-list > .el-button i[data-v-6961ed50] {
  font-size: 24px;
}
.icon-box-list > .el-button[data-v-6961ed50]:hover span {
  -webkit-transform: scale(1.8);
          transform: scale(1.8);
}