.headTitle[data-v-67b96d95] {
  background-color: #fff;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.headTitle .upload-demo[data-v-67b96d95] {
  padding-right: 10px;
}
[data-v-67b96d95] .el-card .is-never-shadow {
  height: 300px;
}
.avatar-uploader .el-upload[data-v-67b96d95] {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
}
.avatar-uploader .el-upload[data-v-67b96d95]:hover {
  border-color: #409eff;
}
.avatar-uploader-icon[data-v-67b96d95] {
  font-size: 28px;
  color: #8c939d;
  width: 178px;
  height: 178px;
  line-height: 178px;
  text-align: center;
}
.avatar[data-v-67b96d95] {
  width: 178px;
  height: 178px;
  display: block;
}
.hide_box[data-v-67b96d95] .el-upload--picture-card {
  display: none;
}
[data-v-67b96d95] .filePreviewDialog .el-dialog__body {
  height: 90% !important;
}
[data-v-67b96d95] .el-dialog__body {
  height: 100%;
}
[data-v-67b96d95] .avue-crud .el-table {
  height: auto !important;
  max-height: calc(100vh - 274px) !important;
}
[data-v-67b96d95] .avueCrud .el-table {
  height: auto !important;
  max-height: calc(100vh - 274px) !important;
}
[data-v-67b96d95] .avueCrudNoTag .el-table {
  height: auto !important;
  max-height: calc(100vh - 234px) !important;
}
[data-v-67b96d95] .noFile .avue-crud .el-table {
  height: 100px !important;
}
[data-v-67b96d95] .noFile .avueCrud .el-table {
  height: 100px !important;
}
[data-v-67b96d95] .noFile .avueCrudNoTag .el-table {
  height: 100px !important;
}
