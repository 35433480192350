.layout-area[data-v-a46211a0] {
  height: 100%;
  overflow: hidden;
}
.layout-area[data-v-a46211a0] .el-scrollbar__wrap {
  margin-bottom: 0 !important;
  overflow-x: auto;
}
.layout-area[data-v-a46211a0] .el-scrollbar__bar.is-horizontal > div {
  display: none;
}
.layout-area[data-v-a46211a0] .el-card {
  width: 100%;
  height: 100%;
}
.layout-area[data-v-a46211a0] .el-card .el-card__body {
  padding: 0;
}
.layout-area .vue-grid-item[data-v-a46211a0] {
  position: relative;
}
.layout-area .vue-grid-item .mask[data-v-a46211a0] {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}