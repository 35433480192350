.todo-box[data-v-c27ddfe8] .el-card__body {
  width: 100%;
  height: calc(100% - 55px);
}
.todo-box .todo-box-body[data-v-c27ddfe8] {
  padding: 0 30px;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
.todo-box .todo-box-body .item[data-v-c27ddfe8] {
  height: 56px;
  display: block;
}
.todo-box .todo-box-body .item i[data-v-c27ddfe8] {
  width: 56px;
  height: 56px;
  margin-right: 14px;
  border-radius: 50%;
  color: #fff;
  display: inline-block;
  vertical-align: top;
  text-align: center;
  line-height: 56px;
  font-size: 30px !important;
}
.todo-box .todo-box-body .item i.icon-ym-flowTodo[data-v-c27ddfe8] {
  background: #f68900;
}
.todo-box .todo-box-body .item i.icon-ym-flowEntrust[data-v-c27ddfe8] {
  background: #1890ff;
}
.todo-box .todo-box-body .item i.icon-ym-flowDone[data-v-c27ddfe8] {
  background: #7b1ae1;
}
.todo-box .todo-box-body .item .text[data-v-c27ddfe8] {
  display: inline-block;
  height: 56px;
}
.todo-box .todo-box-body .item .text .num[data-v-c27ddfe8] {
  font-size: 20px;
  line-height: 36px;
  margin: 0;
}
.todo-box .todo-box-body .item .text .name[data-v-c27ddfe8] {
  font-size: 14px;
  color: #666;
  margin: 0;
}