.commonDialog[data-v-84b71bf4] .el-dialog__header {
  padding: 13px 20px !important;
}
.commonDialog[data-v-84b71bf4] .el-dialog__header .el-dialog__headerbtn {
  top: 11px !important;
  font-size: 20px;
}
.commonDialog[data-v-84b71bf4] .el-dialog__body {
  padding: 15px 20px !important;
  max-height: var(--maxHeight);
  overflow-y: auto;
}
.commonDialog[data-v-84b71bf4] .el-dialog__footer {
  padding: 10px 20px 17px;
  border-top: 1px solid #D9D9D9;
}