.code-dialog[data-v-695c25b3] .el-dialog__body {
  padding: 20px 50px 2px !important;
}
.code-dialog[data-v-695c25b3] .el-dialog__body .qrcode-img {
  width: 300px;
  height: 300px;
  padding: 20px;
}
.code-dialog[data-v-695c25b3] .el-dialog__body .tip {
  text-align: center;
  font-size: 18px;
  margin-top: 10px;
  margin-bottom: 20px;
}
.SNOWS-dialog.previewDialog[data-v-695c25b3] .el-dialog__body {
  padding: 50px 30px !important;
}
.preview-main[data-v-695c25b3] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.preview-main .preview-item[data-v-695c25b3] {
  width: 255px;
  height: 136px;
  background: #f0fff7;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  cursor: pointer;
  padding-left: 30px;
}
.preview-main .preview-item[data-v-695c25b3]:hover {
  opacity: 0.9;
}
.preview-main .preview-item.preview-item-pc[data-v-695c25b3] {
  background: #edfbfd;
}
.preview-main .preview-item.preview-item-pc .preview-icon[data-v-695c25b3] {
  background: #d6f0ff;
  color: #3399fa;
}
.preview-main .preview-item .preview-icon[data-v-695c25b3] {
  width: 56px;
  height: 56px;
  margin-right: 10px;
  background: #ccf7e0;
  border-radius: 10px;
  color: #36ac6c;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  font-size: 30px;
  line-height: 56px;
  text-align: center;
}
.preview-main .preview-item .preview-txt[data-v-695c25b3] {
  height: 56px;
}
.preview-main .preview-item .preview-txt .preview-title[data-v-695c25b3] {
  font-size: 18px;
  line-height: 56px;
}