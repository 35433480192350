@charset "UTF-8";
.contenter[data-v-90915550] {
  width: 100%;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
}
.contenter[data-v-90915550] .el-table .el-button--small.is-round {
  padding: 7px 15px;
}
/* 表格顶部操作项 */
.cus_table_top_operate_part[data-v-90915550] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
}
.el-dialog__body .cus_table_top_operate_part[data-v-90915550] {
  padding-top: 0;
}

/* 表格分页 */
.cus_pagination_part[data-v-90915550] {
  margin-top: 8px;
  text-align: right;
}
.cus_pagination_part[data-v-90915550] .el-pagination__editor.el-input .el-input__inner {
  width: 30px;
}
.table_header_button .el-button[data-v-90915550] {
  margin-bottom: 14px;
}
.checkedInfo[data-v-90915550] {
  height: 40px;
  border: 1px solid #3358E3;
  background: #f1f4ff;
  border-radius: 2px;
  padding: 0 10px;
}
[data-v-90915550] .el-pagination__sizes .el-input .el-input__inner {
  width: 100px !important;
}
[data-v-90915550] .el-pager li.active {
  background: #fd5454 !important;
  color: #ffffff !important;
}
[data-v-90915550] .el-select-dropdown__item.selected {
  color: #fd5454 !important;
}
.hidden[data-v-90915550] {
  display: none;
}
[data-v-90915550] .el-button {
  font-size: 14px;
}