.superQuery-dialog[data-v-6e1cfe80] .el-dialog .el-dialog__body {
  padding: 20px 20px 10px 24px;
}
.superQuery-dialog[data-v-6e1cfe80] .el-dialog .footer-options {
  float: left;
}
.superQuery-dialog[data-v-6e1cfe80] .el-dialog .footer-options .add-btn {
  margin-right: 10px;
}
.superQuery-dialog .superQuery-main .query-noData[data-v-6e1cfe80] {
  text-align: center;
  padding: 20px 0;
}
.superQuery-dialog .superQuery-main .query-noData .noData-img[data-v-6e1cfe80] {
  width: 160px;
  margin-bottom: 10px;
}
.superQuery-dialog .superQuery-main .query-noData .noData-txt[data-v-6e1cfe80] {
  color: #909399;
}
.superQuery-dialog .superQuery-main .matchLogic[data-v-6e1cfe80] {
  margin-bottom: 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  line-height: 32px;
}
.superQuery-dialog .superQuery-main .matchLogic .el-select[data-v-6e1cfe80] {
  width: 220px;
}
.superQuery-dialog .superQuery-main .condition-list[data-v-6e1cfe80] {
  margin-bottom: 10px;
}