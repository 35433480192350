.columnDesign-container[data-v-fa6b1606] {
  height: 100%;
  width: 100%;
  position: relative;
  padding-top: 42px;
}
.columnDesign-container .main-board[data-v-fa6b1606] {
  height: 91%;
  width: auto;
  margin: 0 350px 0 0;
  padding: 20px 10px 10px;
  overflow: auto;
  overflow-x: hidden;
  background: #fff;
  border-radius: 0 0 4px 4px;
}
.columnDesign-container .main-board .cap[data-v-fa6b1606] {
  margin-bottom: 20px;
}
.columnDesign-container .main-board .noSearch[data-v-fa6b1606] {
  text-align: center;
  color: #666;
  padding: 5px;
}
.columnDesign-container .right-board[data-v-fa6b1606] {
  width: 340px;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  padding-top: 3px;
  margin-left: 10px;
  background: #fff;
  border-radius: 4px;
  overflow: hidden;
}
.columnDesign-container .right-board .field-box[data-v-fa6b1606] {
  position: relative;
  height: calc(100% - 90px);
  overflow: hidden;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.columnDesign-container .right-board .field-box .el-scrollbar[data-v-fa6b1606] {
  height: 100%;
  overflow-x: hidden;
}
.columnDesign-container .right-board .field-box .el-scrollbar .el-scrollbar__view[data-v-fa6b1606] {
  padding: 10px;
}
.columnDesign-container .right-board .field-box .el-scrollbar[data-v-fa6b1606].el-scrollbar__bar.is-vertical {
  width: 6px !important;
}
.columnDesign-container .right-board .field-box .searchList[data-v-fa6b1606],
.columnDesign-container .right-board .field-box .columnList[data-v-fa6b1606] {
  height: 100%;
}
.columnDesign-container .right-board[data-v-fa6b1606].top-tabs .el-tabs__header {
  margin-bottom: 0 !important;
}
.columnDesign-container .right-board[data-v-fa6b1606].top-tabs .el-tabs__nav {
  width: 100%;
}
.columnDesign-container .right-board[data-v-fa6b1606].top-tabs .el-tabs__item {
  width: 33%;
  text-align: center;
  padding: 0;
}
.columnDesign-container .right-board[data-v-fa6b1606].top-tabs.top-tabs_app .el-tabs__item {
  width: 25%;
  text-align: center;
  padding: 0;
}
.columnDesign-container .setting-box[data-v-fa6b1606].el-select {
  width: 100%;
}
.columnDesign-container .setting-box .btn-cap[data-v-fa6b1606] {
  color: #909399;
  font-size: 12px;
}
.columnDesign-container .setting-box .btnsList .btn-label[data-v-fa6b1606] {
  width: 60px;
  display: inline-block;
  line-height: 32px;
}
.columnDesign-container .setting-box .btnsList[data-v-fa6b1606].el-checkbox {
  width: 100%;
  margin-bottom: 10px;
  margin-right: 0;
}
.columnDesign-container .setting-box .btnsList[data-v-fa6b1606].el-checkbox .el-checkbox__input {
  line-height: 28px;
}
.columnDesign-container .setting-box .btnsList[data-v-fa6b1606].el-checkbox .el-checkbox__label {
  width: 226px;
}
.columnDesign-container .setting-box .btnsList .upload[data-v-fa6b1606] {
  width: 216px;
  float: right;
  margin-bottom: 10px;
}
.columnDesign-container .setting-box .columnBtnList[data-v-fa6b1606].el-checkbox:last-child {
  margin-bottom: 0;
}
.columnDesign-container .typeList[data-v-fa6b1606] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
      flex-wrap: wrap;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
}
.columnDesign-container .typeList .item[data-v-fa6b1606] {
  width: 150px;
  margin-bottom: 15px;
}
.columnDesign-container .typeList .item .item-img[data-v-fa6b1606] {
  width: 150px;
  height: 100px;
  background: #EBEEF5;
  border-radius: 4px;
  overflow: hidden;
  cursor: pointer;
  position: relative;
  border: 1px solid #EBEEF5;
}
.columnDesign-container .typeList .item .item-img img[data-v-fa6b1606] {
  width: 100%;
  height: 100%;
  z-index: -1;
}
.columnDesign-container .typeList .item .item-img.checked[data-v-fa6b1606] {
  border: 1px solid #409eff;
}
.columnDesign-container .typeList .item .item-img .icon-checked[data-v-fa6b1606] {
  display: block;
  width: 12px;
  height: 12px;
  border: 12px solid #409eff;
  border-left: 12px solid transparent;
  border-top: 12px solid transparent;
  border-bottom-right-radius: 4px;
  position: absolute;
  right: -1px;
  bottom: -1px;
}
.columnDesign-container .typeList .item .item-img .icon-checked i[data-v-fa6b1606] {
  position: absolute;
  top: -2px;
  left: -2px;
  font-size: 12px;
  color: #fff;
}
.columnDesign-container .typeList .item .item-name[data-v-fa6b1606] {
  font-size: 12px;
  color: #707070;
  margin-top: 10px;
  text-align: center;
}
.columnDesign-container .custom-btns-list .custom-item[data-v-fa6b1606] {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  border: 1px dashed #fff;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
}
.columnDesign-container .custom-btns-list .custom-item .close-btn[data-v-fa6b1606] {
  cursor: pointer;
  color: #f56c6c;
}
.columnDesign-container .custom-btns-list .custom-item .el-input + .el-input[data-v-fa6b1606] {
  margin-left: 4px;
}
.columnDesign-container .custom-btns-list .custom-item + .custom-item[data-v-fa6b1606] {
  margin-top: 4px;
}
.columnDesign-container .custom-btns-list .custom-item.sortable-chosen[data-v-fa6b1606] {
  border: 1px dashed #409eff;
}
.columnDesign-container .custom-btns-list .custom-line-icon[data-v-fa6b1606] {
  line-height: 32px;
  font-size: 22px;
  padding: 0 4px;
  color: #606266;
}
.columnDesign-container .custom-btns-list .custom-line-icon .icon-ym[data-v-fa6b1606] {
  font-size: 20px;
  line-height: 32px;
  display: inline-block;
}
.columnDesign-container .custom-btns-list .custom-line-icon.option-drag[data-v-fa6b1606] {
  padding-left: 0;
}
.columnDesign-container .custom-btns-list .custom-line-icon.close-btn[data-v-fa6b1606] {
  padding-right: 0;
}
.columnDesign-container .custom-btns-list .custom-line-value[data-v-fa6b1606] {
  width: 105px;
  -ms-flex-negative: 0;
      flex-shrink: 0;
  line-height: 32px;
  font-size: 14px;
  margin: 0;
}
.columnDesign-container .custom-btns-list .custom-btn[data-v-fa6b1606] {
  padding: 9px !important;
}
.columnDesign-container .custom-btns-list .option-drag[data-v-fa6b1606] {
  cursor: move;
}